import { UserAutoregData } from '@/core/UserAutoregData.interface';

function decodeAndParseFmeUserData(fme_udata?: string | null): Partial<UserAutoregData> {
  let fmeUdataDecoded: Partial<UserAutoregData> = {};

  if (!fme_udata) {
    return {};
  }

  try {
    fmeUdataDecoded = JSON.parse(atob(decodeURIComponent(fme_udata)));
  } catch (error) {
    fmeUdataDecoded = {};
    console.error(error);
  }

  return fmeUdataDecoded;
}

function normalizeQueryParamValue(valueRaw?: string | null): string | undefined {
  if (!valueRaw) {
    return undefined;
  }

  let valueUriDecoded: string | undefined = undefined;

  try {
    valueUriDecoded = decodeURIComponent(valueRaw);
  } catch (error) {
    console.error(error);
    valueUriDecoded = undefined;
  }

  const normalizedValue = valueUriDecoded || valueRaw;

  try {
    const isValidBase64 = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    if (isValidBase64.test(normalizedValue)) {
      return atob(normalizedValue);
    }

    return normalizedValue;
  } catch (error) {
    console.error(error);
    return normalizedValue;
  }
}

function normalizeEmailValue(emailRaw?: string | null): string | undefined {
  if (!emailRaw) {
    return undefined;
  }

  let emailUriDecoded: string | undefined = undefined;

  try {
    emailUriDecoded = decodeURIComponent(emailRaw);

    if (emailUriDecoded.includes('@')) {
      return emailUriDecoded;
    }
  } catch (error) {
    console.error(error);
    emailUriDecoded = undefined;
  }

  try {
    return atob(emailUriDecoded || emailRaw);
  } catch (error) {
    console.error(error);
    return emailUriDecoded || emailRaw;
  }
}

export function composeNormalizedEmailPassingAutoregDataFromQueryParams(): Partial<UserAutoregData> {
  const params = new URLSearchParams(window.location.search);

  const fme_udata = params.get('fme_udata');

  if (fme_udata) {
    return decodeAndParseFmeUserData(fme_udata);
  }

  const emailRaw = params.get('email');

  const autoregData: Partial<UserAutoregData> = {};

  if (emailRaw) {
    autoregData.email = normalizeEmailValue(emailRaw);
  }

  // const nameRaw = params.get('name');
  // const birthdayRaw = params.get('birthday');
  // const ageRaw = params.get('age');

  // if (nameRaw) {
  //   autoregData.name = normalizeQueryParamValue(nameRaw);
  // }

  // if (birthdayRaw) {
  //   const birthday = normalizeQueryParamValue(birthdayRaw);
  //   autoregData.birthday = 
  // }

  // if (ageRaw) {
  //   autoregData.age = normalizeQueryParamValue(ageRaw);
  // }

  return autoregData;
}