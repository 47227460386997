import { AxiosRequestConfig } from 'axios';
import AuthTokenService from '@/services/auth-token.service';
import { API_URL, API_VERSION } from './urls.config';

function stringifyBodyIfJson(data: any, headers?: Record<string, any>) {
  if (!headers || typeof headers['Content-Type'] !== 'string') {
    return data;
  }

  const isContentTypeJson = headers['Content-Type'].toLowerCase().includes('application/json');

  if (data && typeof data === 'object' && isContentTypeJson) {
    return JSON.stringify(data);
  }

  return data;
}

function appendAuthorizationHeader(data: any, headers?: Record<string, any>) {
  const tokenPayloadRaw = AuthTokenService.getToken();

  if (tokenPayloadRaw && headers) {
    // eslint-disable-next-line no-param-reassign
    headers.authorization = tokenPayloadRaw;
  }

  return data;
}

const AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: `${API_URL}/${API_VERSION}`,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  withCredentials: true,
  transformRequest: [
    appendAuthorizationHeader,
    stringifyBodyIfJson,
  ],
};

export default AXIOS_CONFIG;
