import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import errorHandler from './core/error-handler';
import AppInitializationService from './services/app-initialization.service';

const authPromise = AppInitializationService.initialize();

const app = createApp(App, { authPromise, router });
app.config.errorHandler = errorHandler;
app.use(router);

app.mount('#app');
