export default class AuthTokenService {
  public static AUTH_TOKEN_KEY = 'tokenPayload';

  public static getToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  public static setToken(token: string | undefined) {
    if (!token) {
      return;
    }

    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  public static clearToken() {
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
  }
}
