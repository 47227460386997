<template>
  <section v-if="isLoading" class="loading-screen">
    <div class="loader"></div>
    <div style="margin-top: 10px">
      <b>{{ loaderText }}</b>
    </div>
  </section>
  <section v-else>
    <router-view></router-view>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Router } from 'vue-router';

import PushNotificationsService from '@/services/push-notifications.service';
import OfferService from './services/offer.service';
import AuthTokenService from './services/auth-token.service';
import { AuthResponse } from './core';
import { composeNormalizedEmailPassingAutoregDataFromQueryParams } from '@/utils/email-passing-helpers';


export default defineComponent({
  name: 'App',
  props: {
    authPromise: {
      type: Promise,
      required: true,
    },
    router: {
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const landUrl = ref<string | undefined>(undefined);
    const loaderText = 'Loading';

    let responseStatus: number | null = null;
    let response: undefined | AuthResponse = undefined;
    let isPreviewMode: undefined | boolean;

    (props.authPromise as Promise<AuthResponse>)
      .then((res) => {
        isPreviewMode = res.data.isPreviewMode;

        if (isPreviewMode) {
          return;
        }

        PushNotificationsService.askToSubscribe();
        landUrl.value = res.data.preland?.url;
        response = res;

        if (res.data.authToken) {
          AuthTokenService.setToken(res.data.authToken);
        }

        if (res.data.vid) {
          OfferService.setVisitId(res.data.vid);
        }

        OfferService.setIsWebview(!!res?.data?.is_webview);
        OfferService.setIsInApp(!!res?.data?.is_in_app);
      })
      .catch((error) => {
        landUrl.value = undefined;
        responseStatus = error?.response?.status;
      })
      .finally(async () => {
        if (responseStatus === 403) {
          window.location.replace('https://o.love-me.io/c/775cc430-2dac-4e6f-8713-dd4fff6ed221');
          return;
        }

        if (isPreviewMode) {
          isLoading.value = false;
          return;
        }

        if (landUrl.value !== undefined && landUrl.value !== null) {
          await (props.router as Router).replace({ path: `/${landUrl.value}` });
          isLoading.value = false;

          if (!!window.opener) {
            OfferService.handleWindowOpener();
          }

          return;
        }

        await OfferService.handleDirectClickWithoutSecondaryOffer();
      });

    return { isLoading, loaderText };
  },
});
</script>
