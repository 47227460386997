import { ComponentOptionsBase, ComponentPublicInstance } from 'vue';
import { URLS } from '@/configs/urls.config';
import httpService from '@/services/http.service';

// eslint-disable-next-line max-len
function errorHandler(err: unknown, _vm: ComponentPublicInstance<{}, {}, {}, {}, {}, {}, {}, {}, false, ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}>> | null, info: string) {
  if (err instanceof Error) {
    const { stack, message } = err;
    httpService.post(URLS.PUBLIC_CLIENT_ERROR, { stack, message, info });
  } else {
    httpService.post(URLS.PUBLIC_CLIENT_ERROR, { info });
  }
}

export default errorHandler;
