import { URLS } from '@/configs/urls.config';
import UserEvents from '@/core/UserEvents.enum';
import httpService from './http.service';
import UserService from './user.service';

class PushNotificationsService {
  private static urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  private static sendTokenToServer(meta: any) {
    httpService.post(URLS.USER_PUSH_SUBSCRIPTION, { meta });
  }

  private static subscribe(pushManager: PushManager) {
    const key = PushNotificationsService.urlBase64ToUint8Array('BEyr1eMGe16tOnxWmpI44GvcenyLmYkdec8zGpTMvcKf_a_F8p7OF-q9pGVkoQeCxzfA6UyzR1hOXP-9xPyWgxA');

    pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: key
      })
      .then((d) => {
        const token = d.toJSON();
        // send subscription to server in 'meta' field
        PushNotificationsService.sendTokenToServer(token);
      })
      .catch((e) => {
        // console.error(e);
        console.log('User probably blocked push notifications');
        PushNotificationsService.sendTokenToServer(null);
      });
  }

  public static askToSubscribe() {
    if (!navigator.serviceWorker || !window.PushManager || !window.Notification) {
      console.log('the device does not support the Web Push API');
      return;
    }

    navigator.serviceWorker.ready
      .then((reg) => {
        if (!('pushManager' in reg)) {
          return;
        }

        reg.pushManager.getSubscription()
          .then((sub) => {
            if (sub === null && (Notification.permission === 'default' || Notification.permission === 'granted')) {

              UserService.sendUserEvent(UserEvents.push_subscription_show);

              if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                // the device is using Safari
                document.addEventListener('click', () => PushNotificationsService.subscribe(reg.pushManager), { once: true });
              } else {
                PushNotificationsService.subscribe(reg.pushManager);
              }

            } else if (sub === null && Notification.permission === 'denied') {
              console.log('User blocked push notifications');
            } else {
              console.log('Subscription object: ', sub);
            }
          });
      });
  }
}

export default PushNotificationsService;
