import { URLS } from '@/configs/urls.config';
import { AuthResponse } from '@/core';
import { getUnixTimeWithTimezoneOffsetInPSQLFormat } from '@/utils/date-helpers';
import UserEvents from '@/core/UserEvents.enum';

import httpService from './http.service';
import OfferService from './offer.service';
import UserService from './user.service';
import { composeNormalizedEmailPassingAutoregDataFromQueryParams } from '@/utils/email-passing-helpers';

export default class AppInitializationService {
  private static _initBackButtonEventListenerNew() {
    window.history.pushState({ bb_id: 'bo_2' }, '', window.location.href);
    window.history.pushState({ bb_id: 'bo_1' }, '', window.location.href);

    window.addEventListener('popstate', (event) => {
      const bb_id = event?.state?.bb_id ?? undefined;

      UserService.sendUserEvent(UserEvents.back_button_click, bb_id);
      OfferService.redirectToBackButtonOffer();
    });
  }

  private static cleanSessionStorage() {
    sessionStorage.removeItem('preview');
  }

  public static async initialize(): Promise<AuthResponse> {
    this.cleanSessionStorage();

    const urlParams = new URLSearchParams(window.location.search);
    const isPreviewMode = (urlParams.get('preview') === '1');

    if (isPreviewMode) {
      sessionStorage.setItem('preview', '1');
      return { success: true, data: { isPreviewMode: true } };
    }

    let origin = null;

    try {
      origin = (new URL(window.location.href)).origin;
    } catch (error) {
      origin = null;
    }

    let autoregData = composeNormalizedEmailPassingAutoregDataFromQueryParams();

    const authData = {
      aff_id: urlParams.get('aff_id'),
      source: urlParams.get('source'),
      aff_sub: urlParams.get('aff_sub'),
      aff_sub2: urlParams.get('aff_sub2'),
      aff_sub3: urlParams.get('aff_sub3'),
      aff_sub4: urlParams.get('aff_sub4'),
      aff_sub5: urlParams.get('aff_sub5'),
      click_id: urlParams.get('click_id'),
      p10: urlParams.get('p10'),
      retention: urlParams.get('reten'),
      email: autoregData?.email || null,
      cost: urlParams.get('cost'),
      uid: urlParams.get('uid'),
      browser_language: navigator.language,
      origin,
      excluded: [],
      user_info: {
        browser_time: getUnixTimeWithTimezoneOffsetInPSQLFormat(),
        screen_size_height: window.innerHeight,
        screen_size_width: window.innerWidth,
        timezone_offset: new Date().getTimezoneOffset(),
        time_raw: new Date().getTime(),
      },
    };

    const secondary_offer_hash = urlParams.get('so');

    if (secondary_offer_hash) {
      OfferService.setSecondaryOfferHash(secondary_offer_hash);
    }

    const backButtonOfferHash = urlParams.get('bbo');

    if (backButtonOfferHash) {
      OfferService.setBackButtonOfferHash(backButtonOfferHash);
    }

    const { status, data: body } = await httpService.post<AuthResponse>(URLS.USER_AUTH, authData);
    const { success, data } = body;

    this._initBackButtonEventListenerNew();

    return { status, success, data };
  }
}
