const MONTHS: Record<string, string[]> = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  de: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  fr: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'aout',
    'septembr',
    'octobre',
    'novembre',
    'décembre',
  ],
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export function getNumberOfDaysInMonth(month: number | null, year: number | null): number {
  if (month !== null && year !== null) {
    return new Date(year, month, 0).getDate();
  }

  return 0;
}

export function getMonthNameByMonthNumber(monthNumber: number, locale = 'en'): string {
  const dict = MONTHS[locale] ?? MONTHS.en;
  return dict[monthNumber];
}

export function getUnixTimeWithTimezoneOffsetInPSQLFormat() {
  return (new Date().getTime() / 1000) + ((new Date().getTimezoneOffset() * -1) * 60);
}
