export function getApiUrlForEnv(nodeEnv: string, geo: string): string {
  if (nodeEnv === 'production' && geo === 'de') {
    return 'https://api.flirtooy.info';
  }

  if (nodeEnv === 'production' && geo === 'hornyspace') {
    return 'https://api.hornyspace.xyz';
  }

  if (nodeEnv === 'production' && geo === 'ru') {
    return 'https://api.flirtooy.me';
  }

  if (nodeEnv === 'stage') {
    return 'https://stage-api.date-mate.io';
  }

  return 'http://localhost:3000';
}

export function isHornyspace() {
  return process.env.VUE_APP_GEO === 'hornyspace';
}
