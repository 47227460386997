import { URLS } from '@/configs/urls.config';
import { APIResponse } from '@/core';
import UserEvents from '@/core/UserEvents.enum';
import isPreviewMode from '@/utils/is-preview-mode';
import httpService from './http.service';

class UserService {
  public static async sendUserEvent(
    event: UserEvents,
    attr_1?: string,
  ): Promise<{ success: boolean; status?: number }> {
    if (isPreviewMode()) {
      return { success: true };
    }

    const params = { event, attr_1 };

    try {
      const { status, data: body } = await httpService.get<APIResponse>(URLS.USER_ANAL, { params });
      const { success } = body;
      return { status, success };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }
}

export default UserService;
