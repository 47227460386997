/* eslint-disable key-spacing */

import { getApiUrlForEnv } from '@/utils/env-helpers';

export const API_URL = getApiUrlForEnv(process.env.NODE_ENV, process.env.VUE_APP_GEO);
export const API_VERSION = 'v1';

export const URLS = {
  PUBLIC_CLIENT_ERROR:    '/public/client-error',
  USER_AUTH:              '/user/auth',
  USER_ANAL:              '/user/anal',
  USER_PUSH_SUBSCRIPTION: '/user/push-subscription',
  CLICK:                  '/click',
  CLICK_PRELAND:          '/click/preland',
  OFFER:                  '/offer',
};
